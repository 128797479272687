// commonly-used colors in the application

$primary-color: #00AAA7; // used in: .btn.btn-primary .btn.btn-secondary.. input.. .checkbox.. in file: styles.scss
                          // used in: .header.header-component in file: header.component.scss
                          // used in: .highlighted in file: import.component.scss

$text-color: #fff;      // used in:.btn.btn-primary .btn.btn-primary:hover  in file:styles.scss
                          // used in: .slidenav .sidenav-content .nav-link  .nav-group in file:  content.component.scss 

$hover-color: #008E8A; // used in: .btn.btn-primary:hover .nav .nav-link:hover textarea:focus .radio input[type="radio"]:checked+label::before in file:styles.scss
$secondary-color: #006865; // used in: .nav .nav-item.active>.nav-link .btn.btn-secondary:hover...  in file:styles.scss                  
$secondary-background-color: #BBDDD7;   //used in: btn.btn-secondary:hover... in file:styles.scss
$slidenav-color: #0C343D; // used in: .slidenav in file: content.component.scss
$nav-link-hover-color: #313131; //used in: .nav-link:hover in file: content.component.scss
$nav-link-active-color: #4C747D; //used in: .nav-link.active in file: content.component.scss
$navgroup-label-color:  #0C343D; // used in: .nav-group in file:content.component.scss
$box-shadow-color:  rgba(0,0,0,0.2); // used in: .spinner-component in file: spinner.component.scss

$header-title-color:  #565656; // used in: .header.header-component.branding.title in file: item-list.component.scss
$header-background: #00AAA7; // used in: .header-background, .header.header-component

$content-color: #fff; // used in: .slidenav in file import.component.scss
                        // used  in: .content-area in file content.component.scss
                        //used in: .spinner-component in file spinner.component.scss

$border-botom-time: #9a9a9a; // used in input type time

$datagrid-checked:  #318700; // used in .datagrid-cell clr-icon[shape="check"] in file: item-list.component.scss
$datagrid-unchecked: #e62700; // used in .datagrid-cell clr-icon[shape="times"] in file item-list.component.scss

$user-line-quality-1: #318700;
$user-line-quality-2: #e67e22;
$user-line-quality-3: #e62700;

$color-hover: lightgrey; // used in color picker component

$tooltip-icon: #565656; // used in configuration section and in grids

$calendar-header: rgba(251, 176, 176, 0.00);
$calendar-border: #CFCFCF;
$calendar-hover: #CFCFCF;
$calendar-active: #757575;
$calendar-today: $primary-color;
$calendar-depot-background: $primary-color;
$calendar-depot-color: $text-color;
$calendar-exception-color: #C20000;
